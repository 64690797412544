"use client";
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */

import React, { MouseEventHandler, useRef } from "react";

import {
  MdArrowForward as ArrowForwardIcon,
  MdArrowBack as ArrowBackIcon,
} from "react-icons/md";

import { LinkCardProps } from "lib/types";

import AnimatedLink from "./animated-link";
import { Typography } from "./typography";

export const LinkCard: React.FC<LinkCardProps> = ({
  cardFooter,
  centered = false,
  centeredIcon = false,
  children,
  className,
  color = "beige",
  colorBorder,
  component = "div",
  containerQuery = false,
  hasBackgroundGraphic = false,
  hasFooterImage = false,
  headingLevel = "h3",
  headingStartAdornment,
  headingStyles,
  href,
  internalHref,
  lighterDarkModeBg = false,
  linkAriaDescribedby,
  reverse = false,
  reverseAdornment = false,
  srOnlyHeadingAdornment,
  textColor = "normal",
  title,
}) => {
  const Card = component;
  const linkRef = useRef<HTMLAnchorElement>(null);
  const cardColor = {
    beige: "bg-beige",
    lightblue: "bg-lightblue",
    red: "bg-red",
  }[color];

  // eslint-disable-next-line consistent-return
  const handleCardClick: MouseEventHandler<HTMLDivElement> = (event): void => {
    if (
      linkRef?.current !== undefined &&
      // @ts-ignore
      !linkRef?.current?.contains(event.target)
    ) {
      // @ts-ignore
      return linkRef?.current?.click();
    }
    // Add your code here to handle the click event on the card element
  };
  const headingWithLineBreaks = title?.split("   ");

  const cardBackgroundGraphicClass = hasBackgroundGraphic
    ? "link-card-graphic"
    : "";

  const colorBorderStyles = colorBorder
    ? {
        yellow: "border-yellow border-r-[20px]",
        orange: "border-orange border-r-[20px]",
        blue: "border-blue border-r-[20px]",
      }[colorBorder]
    : "";

  const footerImageStyles = hasFooterImage
    ? "flex flex-col justify-between"
    : "";

  const getPaddingStyles = `px-4 sm:px-6 md:px-8 py-6 md:py-8 ${
    hasBackgroundGraphic ? "pb-16 mdplus:pb-16" : ""
  } ${centeredIcon ? "pr-4 mdplus:pr-4" : ""} `;

  const getColorStyles = `${cardColor} ${
    lighterDarkModeBg ? "dark:bg-slate-700/80" : "dark:bg-slate-800/80"
  }`;

  const sharedStyles = `more-contrast-border link-card group relative z-20 flex cursor-pointer flex-col justify-between space-y-3 rounded-lg shadow-sm transition-background ${
    className || ""
  } ${containerQuery === true ? "@container" : ""}`;

  const Icon = reverse ? ArrowBackIcon : ArrowForwardIcon;

  const LinkComponent = AnimatedLink;

  return (
    <Card
      className={`${sharedStyles} ${cardBackgroundGraphicClass} ${colorBorderStyles} ${getColorStyles} ${getPaddingStyles} ${footerImageStyles}`}
      onClick={handleCardClick}
    >
      <div>
        <LinkComponent
          aria-describedby={linkAriaDescribedby}
          className={`flex ${centered ? "items-center" : ""} ${
            reverse ? "flex-row-reverse" : "flex-row justify-between"
          } flex-wrap gap-x-4 gap-y-2 no-underline focus-visible:!outline-none @[16rem]:flex-nowrap`}
          href={internalHref || href}
          ref={linkRef}
        >
          <div
            className={`flex ${
              headingStartAdornment && reverseAdornment
                ? "flex-row-reverse"
                : "flex-row"
            } flex-wrap gap-4 @sm:flex-nowrap`}
          >
            {headingStartAdornment && headingStartAdornment}
            <Typography
              className={`${
                children !== undefined ? "mb-3" : ""
              } inline-flex w-fit max-w-prose ${
                headingLevel === "p"
                  ? "text-lg font-semibold"
                  : "text-xl font-semibold lg:text-2xl"
              } underline-offset-[0.15em] group-focus-within:underline group-hover:underline more-contrast:underline ${
                textColor === "yellow" ? "dark:text-yellow" : ""
              } ${headingStyles}`}
              component={headingLevel}
              variant="h3"
            >
              {srOnlyHeadingAdornment && (
                <span className="sr-only">{srOnlyHeadingAdornment}</span>
              )}
              {/*  Hacky solution to insert soft hyphen when editors add triple space  */}
              {headingWithLineBreaks?.map((substring, index) =>
                headingWithLineBreaks.length > 1 && index > 0 ? (
                  <React.Fragment key={substring}>
                    &shy;{substring}
                  </React.Fragment>
                ) : (
                  <React.Fragment key={substring}>{substring}</React.Fragment>
                ),
              )}
            </Typography>
          </div>

          <Icon
            className={`${
              centered ? "" : "mb-4"
            } shrink-0 text-4xl opacity-70 group-focus-within:opacity-100 group-hover:opacity-100 more-contrast:opacity-100 ${
              colorBorder !== undefined
                ? `${centeredIcon ? "" : "absolute bottom-0 right-4"}`
                : ""
            }`}
          />
        </LinkComponent>
        {children}
      </div>

      {cardFooter}
    </Card>
  );
};
export default LinkCard;
